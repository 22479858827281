import { relayStylePagination } from '@apollo/client/utilities';
import { PossibleTypesMap, TypePolicies, TypePolicy } from '@sixfold/app-data-framework';

import { paginationKeyArgs } from './pagination';

export const possibleTypes: PossibleTypesMap = {
  CombinedEvent: ['TourEvent', 'StopEvent'],
};

function countedConnectionTypePolicy(fieldName: string): TypePolicy {
  return {
    merge: true,

    fields: {
      [fieldName]: relayStylePagination(paginationKeyArgs),
    },
  };
}

function connectionTypePolicy(): TypePolicy {
  return relayStylePagination(paginationKeyArgs);
}

export const typePolicies: TypePolicies = {
  // Complex types with various field policies
  Company: {
    fields: {
      companyRelationships: connectionTypePolicy(),
      providingVisibilityCompanies: { merge: true },
      receivingVisibilityCompanies: { merge: true },
      dataNetworkReport: { merge: true },
      dataNetworkVisibilityReport: { merge: true },
      me: { merge: true },
      tourReportsByCarrier: connectionTypePolicy(),
      tourReportsByShipper: connectionTypePolicy(),
      userGroupsOnPlatform: connectionTypePolicy(),
      relationIdentifiersConnection: connectionTypePolicy(),
      bulkInvitationSet: {
        merge: true,
      },
      latestBulkInvitationSet: {
        merge: true,
      },
      featureFlagAuditLogConnection: connectionTypePolicy(),
    },
  },

  CompanyPlace: {
    keyFields: ['place_id'],

    fields: {
      possibleAddresses: connectionTypePolicy(),
    },
  },

  CompanyStopAddress: {
    keyFields: ['stopAddressId'],

    fields: {
      closeByAddresses: connectionTypePolicy(),
    },
  },

  Tour: {
    fields: {
      activeVehicleAllocation: { merge: true },
      allocatableVehicles: connectionTypePolicy(),
      stops: { merge: false },
      tourTrafficEntries: connectionTypePolicy(),
      vehicleAllocations: { merge: false },
      vehicleHistory: { merge: false },
      oceanVehicleHistory: { merge: false },
      warnings: { merge: false },
    },
  },

  TourStop: { keyFields: ['stop_id'] },

  UserGroupOnPlatform: {
    keyFields: ['groupId'],

    fields: {
      users: connectionTypePolicy(),
    },
  },

  // Counted connection types
  CountedBulkInvitationSetConnection: countedConnectionTypePolicy('bulkInvitationSets'),
  CountedBulkInvitationSetItemConnection: countedConnectionTypePolicy('items'),
  CountedCarrierConnection: countedConnectionTypePolicy('carriers'),
  CountedCompanyBookingLocation: countedConnectionTypePolicy('bookingLocations'),
  CountedCompanyPlaceConnection: countedConnectionTypePolicy('places'),
  CountedCompanyRelationConnection: countedConnectionTypePolicy('networkRelations'),
  CountedCompanyStopAddressConnection: countedConnectionTypePolicy('addresses'),
  CountedCompanyStopStatesConnection: countedConnectionTypePolicy('stopStatesIdentifiers'),
  CountedCompanyTourFilterConnection: countedConnectionTypePolicy('companyTourFilters'),
  CountedCompanyTransportGroupConnection: countedConnectionTypePolicy('companyTransportGroups'),
  CountedCompanyTransportNetworkFilterConnection: countedConnectionTypePolicy('companyTransportNetworkFilters'),
  CountedCustomFieldValueConnection: countedConnectionTypePolicy('values'),
  CountedDashboardsConnection: countedConnectionTypePolicy('dashboards'),
  CountedDataNetworkVisibilityReportCompanyConnection: countedConnectionTypePolicy('companies'),
  CountedMainCarrierConnection: countedConnectionTypePolicy('mainCarriers'),
  CountedPlaceTourConnection: countedConnectionTypePolicy('tours'),
  CountedProvidingVisibilityCompaniesConnection: countedConnectionTypePolicy('companies'),
  CountedReceivingVisibilityCompaniesConnection: countedConnectionTypePolicy('companies'),
  CountedShipperConnection: countedConnectionTypePolicy('shippers'),
  CountedSiteConnection: countedConnectionTypePolicy('sites'),
  CountedTelematicsIntegrationConnection: countedConnectionTypePolicy('integrations'),
  CountedTourConnection: countedConnectionTypePolicy('tours'),
  CountedOceanIdentifiersConnection: countedConnectionTypePolicy('tourIdentifiers'),
  CountedOceanLocationsOptionConnection: countedConnectionTypePolicy('options'),
  CountedOceanTourConnection: countedConnectionTypePolicy('tours'),
  CountedTripsIdentifiersConnection: countedConnectionTypePolicy('tourIdentifiers'),
  CountedTripsOptionConnection: countedConnectionTypePolicy('options'),
  CountedTripsTourConnection: countedConnectionTypePolicy('tours'),
  CountedUserFilterConnection: countedConnectionTypePolicy('userFilters'),
  CountedUserInCompanyProfileConnection: countedConnectionTypePolicy('users'),
  CountedVehicleConnection: countedConnectionTypePolicy('vehicles'),
  CountedVehicleGrantPartnerConnection: countedConnectionTypePolicy('partners'),
  CountedVehicleInGroupConnection: countedConnectionTypePolicy('vehicles'),
  CountedWidgetsConnection: countedConnectionTypePolicy('widgets'),
  CountedOrderItemConnection: countedConnectionTypePolicy('orderItemIdentifiers'),
  CountedOrderItemCustomFieldConnection: countedConnectionTypePolicy('orderItemCustomFieldIdentifiers'),
  CountedStopCompanyNameConnection: countedConnectionTypePolicy('stopCompanyNameIdentifiers'),

  // Non-counted connection types
  CarrierReportConnection: connectionTypePolicy(),
  TelematicsProviderConnection: connectionTypePolicy(),
  ShipperReportConnection: connectionTypePolicy(),
  VehicleProfileConnection: connectionTypePolicy(),

  // Edge types are not normalized (i.e they should only appear on the connection they appear on)
  CarrierReportEdge: { keyFields: false },
  CompanyBookingLocationEdge: { keyFields: false },
  CompanyPlaceEdge: { keyFields: false },
  CompanyProfileEdge: { keyFields: false },
  CompanyStopAddressEdge: { keyFields: false },
  CompanyStopStatesEdge: { keyFields: false },
  CompanyTourFilterEdge: { keyFields: false },
  CompanyTransportNetworkFilterEdge: { keyFields: false },
  CustomFieldValueEdge: { keyFields: false },
  DashboardEdge: { keyFields: false },
  DataNetworkVisibilityReportCompanyEdge: { keyFields: false },
  ShipperReportEdge: { keyFields: false },
  SiteEdge: { keyFields: false },
  TelematicsProviderEdge: { keyFields: false },
  TourEdge: { keyFields: false },
  TripsOptionEdge: { keyFields: false },
  TripsTourIdentifierEdge: { keyFields: false },
  UserFilterEdge: { keyFields: false },
  UserInCompanyProfileEdge: { keyFields: false },
  VehicleEdge: { keyFields: false },
  VehicleGrantPartnerEdge: { keyFields: false },
  VehicleProfileEdge: { keyFields: false },
  WidgetEdge: { keyFields: false },
  ProvidingVisibilityCompanyEdge: { keyFields: false },
  ReceivingVisibilityCompanyEdge: { keyFields: false },
  NetworkRelationEdge: { keyFields: false },
  BulkInvitationSetItemEdge: { keyFields: false },
  OrderItemIdentifierEdge: { keyFields: false },
  OrderItemCustomFieldEdge: { keyFields: false },
  StopCompanyNameEdge: { keyFields: false },
  StopCompanyName: { keyFields: false },

  CompanyInvitationRecipient: { keyFields: false },
  InvitationHistoryItem: { keyFields: false },
  InvitationHistoryItemRecipient: { keyFields: false },
  GeofenceZone: { keyFields: false },
  Geofence: { keyFields: false },
  CompanyIdentifierEdge: { keyFields: false },

  // Types we do not want to normalise, but don't mind merging
  // (i.e child types that can be combined between separate requests)
  Address: { keyFields: false, merge: true },
  ApplicationDeepLink: { keyFields: false, merge: true },
  BreakCenterPosition: { keyFields: false, merge: true },
  Bounds: { keyFields: false, merge: true },
  CircleGeofence: { keyFields: false, merge: true },
  CompanyAddress: { keyFields: false, merge: true },
  CompanyAssets: { keyFields: false, merge: true },
  CompanyBookingLocation: { keyFields: false, merge: true },
  CompanyCountByOnboardingStatus: { keyFields: false, merge: true },
  CompanyIdAndVisibilityServices: { keyFields: false, merge: true },
  CompanyInviteMetadata: { keyFields: false, merge: true },
  CompanyInviteRecipient: { keyFields: false, merge: true },
  CompanyStopStates: { keyFields: false, merge: true },
  CompanyVatNumber: { keyFields: false, merge: true },
  CompanyVehicles: { keyFields: false, merge: true },
  ConnectedCustomField: { keyFields: false, merge: true },
  ConnectionParameter: { keyFields: false, merge: true },
  ConnectionParameterEnumValue: { keyFields: false, merge: true },
  CustomField: { keyFields: false, merge: true },
  DateTimeWithTimezone: { keyFields: false, merge: true },
  DelayTimeRangeOutput: { keyFields: false, merge: true },
  GeoPosition: { keyFields: false, merge: true },
  LocationsAndMilestones: { keyFields: false, merge: true },
  Milestone: { keyFields: false, merge: true },
  MilestoneDelayOutput: { keyFields: false, merge: true },
  MilestoneLocation: { keyFields: false, merge: true },
  OceanMilestones: { keyFields: false, merge: true },
  OceanRoute: { keyFields: false, merge: true },
  OceanRouteLeg: { keyFields: false, merge: true },
  OceanDemurrage: { keyFields: false, merge: true },
  OceanDemurrageCost: { keyFields: false, merge: true },
  OceanCostDetails: { keyFields: false, merge: true },
  OrderItemCustomField: { keyFields: false, merge: true },
  TransportAlert: { keyFields: false, merge: true },
  OnboardingAction: { keyFields: false, merge: true },
  PageInfo: { keyFields: false, merge: true },
  PlaceGeofence: { keyFields: false, merge: true },
  PlaceGeofenceWithZone: { keyFields: false, merge: true },
  PointLocation: { keyFields: false, merge: true },
  PolygonGeofence: { keyFields: false, merge: true },
  Polyline: { keyFields: false, merge: true },
  Position: { keyFields: false, merge: true },
  RestrictedVehicleStatus: { keyFields: false, merge: true },
  Route: { keyFields: false, merge: true },
  RouteLeg: { keyFields: false, merge: true },
  SharedCompanyTourFilter: { keyFields: false, merge: true },
  SharedTourDelivery: { keyFields: false, merge: true },
  SharedTourStop: { keyFields: false, merge: true },
  StopAllocation: { keyFields: false, merge: true },
  TelematicsValidation: { keyFields: false, merge: true },
  TemperatureSensor: { keyFields: false, merge: true },
  TemperatureSensorReading: { keyFields: false, merge: true },
  TimeRange: { keyFields: false, merge: true },
  Timeslot: { keyFields: false, merge: true },
  TourAssignment: { keyFields: false, merge: true },
  TokenBrandingAssets: { keyFields: false, merge: true },
  TokenResources: { keyFields: false, merge: true },
  TourCalculatedEmissions: { keyFields: false, merge: true },
  TourCO2Emissions: { keyFields: false, merge: true },
  TourEmissionsReceived: { keyFields: false, merge: true },
  FuelBasedEmissions: { keyFields: false, merge: true },
  TourCountByFailureReason: { keyFields: false, merge: true },
  TourCustomFieldSearchType: { keyFields: false, merge: true },
  TourCustomFieldSearchValueType: { keyFields: false, merge: true },
  TourFailureReason: { keyFields: false, merge: true },
  TourFilter: { keyFields: false, merge: true },
  TourLocation: { keyFields: false, merge: true },
  ToursCountByDelayStatus: { keyFields: false, merge: true },
  ToursCountByStatus: { keyFields: false, merge: true },
  TourSignal: { keyFields: false, merge: true },
  TourVisibilitySource: { keyFields: false, merge: true },
  TourWarning: { keyFields: false, merge: true },
  TransportNetworkCustomFieldSearchType: { keyFields: false, merge: true },
  TransportNetworkCustomFieldSearchValueType: { keyFields: false, merge: true },
  TransportNetworkFilter: { keyFields: false, merge: true },
  TripsOption: { keyFields: false, merge: true },
  TripsTourIdentifier: { keyFields: false, merge: true },
  UnifiedCompanyOnboardingEvent: { keyFields: false, merge: true },
  UnifiedCompanyRelationshipOnboardingStatusTransition: { keyFields: false, merge: true },
  UserInCompany: { keyFields: false, merge: true },
  UserInCompanyProfile: { keyFields: false, merge: true },
  UserInCompanyConfiguration: { keyFields: false, merge: true },
  VehicleAllocation: { keyFields: false, merge: true },
  VehicleBreakHistoryEntry: { keyFields: false, merge: true },
  VehicleData: { keyFields: false, merge: true },
  VehicleGrantPartner: { keyFields: false, merge: true },
  VehicleHistoryPolyline: { keyFields: false, merge: true },
  VehicleHistoryPolylineMetadata: { keyFields: false, merge: true },
  VehiclePermissions: { keyFields: false, merge: true },
  VehicleStatus: { keyFields: false, merge: true },
  VehicleTemperature: { keyFields: false, merge: true },
  VehicleTracker: { keyFields: false, merge: true },
  VisibilityIssue: { keyFields: false, merge: true },
  WidgetFilter: { keyFields: false, merge: true },
  WidgetPreview: { keyFields: false, merge: true },
  OceanLocationsOption: { keyFields: false, merge: true },
  OceanLocationsOptionEdge: { keyFields: false, merge: true },
  OceanTransportLocationOutput: { keyFields: false, merge: true },
  TransportLocationOutput: { keyFields: false, merge: true },
  OceanTourIdentifier: { keyFields: false, merge: true },
  OceanTourIdentifierEdge: { keyFields: false, merge: true },
  OceanDemurrageFilterOutput: { keyFields: false, merge: true },
  TransportAlertsFilterOutput: { keyFields: false, merge: true },
  LocationMilestoneOutput: { keyFields: false, merge: true },
  LocationMilestoneTimelineEventOutput: { keyFields: false, merge: true },
  TimeRangeSugarDateOutput: { keyFields: false, merge: true },
  ReceivingVisibilityStatistics: { keyFields: false, merge: true },
  NetworkRelationStatistics: { keyFields: false, merge: true },
  CompanyTransportGroup: { keyFields: false, merge: true },
  CompanyTransportGroupEdge: { keyFields: false, merge: true },
  NotificationTrigger: { keyFields: false, merge: true },
  EtaComparison: { keyFields: false, merge: true },
  Recipient: { keyFields: false, merge: true },
  ImpactDaysOutput: { keyFields: false, merge: true },
  SendCompanyInvitationsResult: { keyFields: false, merge: true },
  CreateCompanyInvitationsResult: { keyFields: false, merge: true },
  CreateBulkInvitationSetResult: { keyFields: false, merge: true },
  UpdateBulkInvitationSetResult: { keyFields: false, merge: true },
  DeleteBulkInvitationSetResult: { keyFields: false, merge: true },
  SendBulkInvitationSetResult: { keyFields: false, merge: true },
  CompanyVatNumberChangeResult: { keyFields: false, merge: true },
  BulkInvitationMetadata: { keyFields: false, merge: true },
  BulkInvitationSetStatistics: { keyFields: false, merge: true },
  ItemCountByStatus: { keyFields: false, merge: true },
  BulkInvitationRecipient: { keyFields: false, merge: true },
  VehicleCountByStatus: { keyFields: false, merge: true },
  WithdrawCompanyInvitationResult: { keyFields: false, merge: true },
  CreateOrUpdateRelationIdentifiersResult: { keyFields: false, merge: true },
  OrderItemIdentifier: { keyFields: false, merge: true },
  StopCompanyNameIdentifiers: { keyFields: false, merge: true },
  UpdateUserNotificationConfigResponse: { keyFields: false, merge: true },

  CompanyFilterBasedNotifications: { keyFields: false, merge: true },
  TourEventMetadata: { keyFields: false, merge: true },
  StopEventMetadata: { keyFields: false, merge: true },
  TourFailureReasonMetadata: { keyFields: false, merge: true },
  VesselNameFilterOutput: { keyFields: false, merge: true },
  OrderItemCustomFieldOutput: { keyFields: false, merge: true },
  TripVesselNames: { keyFields: false, merge: true },
  ExceptionFile: { keyFields: false, merge: true },
  RelatedTransportForListView: { keyFields: false, merge: true },

  // Types that just have a custom key field
  Dashboard: { keyFields: ['dashboardId'] },
  CarrierReport: { keyFields: ['carrier', ['company_id']] },
  CombinedEvent: { keyFields: ['event_id'] },
  CompanyAsset: { keyFields: ['url'] },
  CompanyOverview: { keyFields: ['companyId'] },
  CompanyPlaceAddress: { keyFields: ['addressId'] },
  CompanyPlatform: { keyFields: ['platform_id', 'company_id_on_platform'] },
  CompanyProfile: { keyFields: ['company_id'] },
  CompanySignupCompleted: { keyFields: ['companySignupId'] },
  CompanyTelematicsIntegration: { keyFields: ['telematicsIntegrationId'] },
  CustomFieldValue: { keyFields: ['value'] },
  DataNetworkVisibilityReportCompany: { keyFields: ['companyId'] },
  ExternalTourDeliveryEvent: { keyFields: ['eventId'] },
  ExternalTourEvent: { keyFields: ['eventId'] },
  ExternalTourStopEvent: { keyFields: ['eventId'] },
  ShipperReport: { keyFields: ['shipper', ['company_id']] },
  StopEvent: { keyFields: ['event_id'] },
  TokenBrandingAsset: { keyFields: ['url'] },
  TourDelivery: { keyFields: ['deliveryId'] },
  TourDeliveryTree: { keyFields: ['deliveryTreeId'] },
  TourEvent: { keyFields: ['event_id'] },
  UserOnPlatform: { keyFields: ['userIdOnPlatform'] },
  UserProfile: { keyFields: ['user_id'] },
  VehicleBreakHistory: { keyFields: ['breakId'] },
  VehicleGrant: { keyFields: ['grantId'] },
  VehicleTrackerTour: { keyFields: ['tour_id'] },
  VehicleTrackerTourStop: { keyFields: ['stop_id'] },
  ProvidingVisibilityCompany: { keyFields: ['companyId'] },
  ReceivingVisibilityCompany: { keyFields: ['companyId'] },
  FilterBasedTransportNotification: { keyFields: ['notificationId'] },
  NetworkRelation: { keyFields: ['entityId', 'type'] },
  BulkInvitationSetItem: { keyFields: ['bulkInvitationSetItemId'] },
  TourException: { keyFields: ['exceptionId'] },
  CompanyIdentifier: { keyFields: ['companyIdentifierId'] },
};
